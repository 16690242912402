import React from "react";
export const MENUITEMS = [
  {
    title: "Inicio",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: `${process.env.PUBLIC_URL}/inicio`,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA'],
    active: true,
    bookmark: true,
    children: [ 
      { title: "Inicio", type: "sub" },
      {
        title: "Inicio",
        type: "link",
        path: `${process.env.PUBLIC_URL}/inicio`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA'],
      },
    ],
  },
  {
    title: "Ventas",
    icon: <i className="pe-7s-wallet pe-lg"></i>,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS','COTIZADOR','SUPERVISOR'],
    path: `${process.env.PUBLIC_URL}/modulos`,
    active: false,
    bookmark: true,
    children: [
      { title: "Módulos", type: "sub" },
      {
        title: "Cotizar Válvulas",
        type: "link",
        path: `${process.env.PUBLIC_URL}/cotizaciones`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','COTIZADOR','SUPERVISOR'],
      },
      {
        title: "Cotizar Partes",
        type: "link",
        path: `${process.env.PUBLIC_URL}/cotizaciones-partes`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','COTIZADOR','SUPERVISOR'],
      },
      {
        title: "Llaves y Precios",
        type: "link",
        path: `${process.env.PUBLIC_URL}/prices`,
        permissions: ['ADMIN','SISTEMAS'],
      },
    ],
  },
  {
    title: "Producción",
    icon: <i className="pe-7s-network pe-lg"></i>,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA','SUPERVISOR','ALMACEN'],
    path: `${process.env.PUBLIC_URL}/modulos`,
    active: false,
    bookmark: true,
    children: [
      { title: "Módulos", type: "sub" },
      {
        title: "Servicios",
        type: "link",
        path: `${process.env.PUBLIC_URL}/servicios`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA','SUPERVISOR','ALMACEN'],
      },
      {
        title: "Valvulas",
        type: "link",
        path: `${process.env.PUBLIC_URL}/valvulas`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA','SUPERVISOR','ALMACEN'],
      },
      {
        title: "Reparación de Valvulas",
        type: "link",
        path: `${process.env.PUBLIC_URL}/reparacion-valvulas`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA','SUPERVISOR','ALMACEN'],
      },
      // {
      //   title: "Calendario de Entregas",
      //   type: "link",
      //   path: `${process.env.PUBLIC_URL}/calendario`,
      //   permissions: ['ADMIN','GERENTE','SISTEMAS','CAPTURISTA'],
      // },
    ],
  },
  {
    title: "Compras",
    icon: <i className="pe-7s-shopbag pe-lg"></i>,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN','COTIZADOR'],
    path: `${process.env.PUBLIC_URL}/modulos`,
    active: false,
    bookmark: true,
    children: [
      { title: "Almacen", type: "sub" },
      {
        title: "Recepción y Lógistica",
        type: "link",
        path: `${process.env.PUBLIC_URL}/logistica`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN'],
      },
      {
        title: "Ordenes de Compra",
        type: "link",
        path: `${process.env.PUBLIC_URL}/ordenes-compra`,
        permissions: ['ADMIN','GERENTE','SISTEMAS'],
      },
      {
        title: "Garantías",
        type: "link",
        path: `${process.env.PUBLIC_URL}/garantias`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN','COTIZADOR'],
      },
    ],
  },
  {
    title: "Almacen",
    icon: <i className="pe-7s-ribbon pe-lg"></i>,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN','COTIZADOR','INVENTARIO'],
    path: `${process.env.PUBLIC_URL}/modulos`,
    active: false,
    bookmark: true,
    children: [
      { title: "Almacen", type: "sub" },
      {
        title: "Inventario",
        type: "link",
        path: `${process.env.PUBLIC_URL}/inventario`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN','COTIZADOR','INVENTARIO'],
      },
      {
        title: "Movimientos",
        type: "link",
        path: `${process.env.PUBLIC_URL}/movimientos`,
        permissions: ['ADMIN','GERENTE','SISTEMAS','ALMACEN'],
      },
    ],
  },
  {
    title: "Administrar",
    icon: <i className="pe-7s-config"></i>,
    type: "sub",
    permissions: ['ADMIN','GERENTE','SISTEMAS'],
    path: `${process.env.PUBLIC_URL}/administrar`,
    active: true,
    children: [
      { title: "Administrar", type: "sub" },
      // {
      //   title: "Ajustes",
      //   type: "link",
      //   path: `${process.env.PUBLIC_URL}/caja-movimientos`,
      //   permissions: ['ADMIN','SISTEMAS'],
      // },
      {
        title: "Clientes",
        type: "link",
        path: `${process.env.PUBLIC_URL}/clientes`,
        permissions: ['ADMIN','GERENTE','SISTEMAS'],
      },
      {
        title: "Proveedores",
        type: "link",
        path: `${process.env.PUBLIC_URL}/proveedores`,
        permissions: ['ADMIN','GERENTE','SISTEMAS'],
      },
      {
        title: "Usuarios",
        type: "link",
        path: `${process.env.PUBLIC_URL}/usuarios`,
        permissions: ['ADMIN','GERENTE','SISTEMAS'],
      },
    ],
  },
];
