import React, { useState } from 'react';
import { API_ITEM } from '../../redux/apiRoutes';
import axios from 'axios';
import { Label, Row, Col, Button } from 'reactstrap';
import SweetAlert from "sweetalert2";

export const SerachPedimentsComponent = (props) => {
    const pediment = props.pediment;
    const [selectedParts, setselectedParts] = useState([]);
    const [partLoading, setPartLoading] = useState(false);
    const [valKeyValue,setvalKeyValue] = useState('');
    const getPartKey = async (value) => {
      setvalKeyValue(value);
  
      if(value===''){
        setselectedParts([]);
        return;
      }
      setPartLoading(true);
  
      await axios.get(`${API_ITEM}?country=MX&type=VAL&key=${encodeURIComponent(value)}`).then(async response => {
        if(response.data){
          if(response.data.docs.length>0){
            setselectedParts(response.data.docs[0].bom);
          }else{
            setselectedParts([]);
          }
        }
        setPartLoading(false);
      }).catch(e => {
        setPartLoading(false);
      });
    }
  
    const addBom = () => {
      SweetAlert.fire({
        title: "¿Continuamos?",
        text: `Se sustitirán todos los materiales del pedimento existentes por los de la llave: ${valKeyValue}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          let temporalParts = [];

          selectedParts.map((part,i)=>{
            i++;
            temporalParts.push({
              pid: i,
              number: part.key,
              description: part.description,
              qty: part.qty,
              pediment: '',
            });
          });

          for(let i=temporalParts.length+1; i<= 40; i++){
            temporalParts.push({
              pid: i,
              number: '',
              description: '',
              qty: '',
              pediment: '',
            });
          }
      
          console.log(temporalParts)
          props.setPediments(temporalParts);
          setselectedParts([]);
          setvalKeyValue('');
        }
      });
    }

    const changePedimentValue = (id,type,value) => {
      let pedimentValues = pediment.filter((pediment)=>{
        if(pediment.pid===id){
          switch(type){
            case 'number':
              pediment.number = value;
            break;
            case 'description':
              pediment.description = value;
            break;
            case 'qty':
              pediment.qty = value;
            break;
            case 'pediment':
              pediment.pediment = value;
            break;
          }
        }
        return pediment;
      });
  
      props.setPediments(pedimentValues);
    }
  
    return (
      <div>
        <Row className="g-3 dflex mb-3">
            <Col md="12">
                <Label>Cargar Materiales por BOM:</Label>
                <input value={valKeyValue} onChange={(e)=>getPartKey(e.target.value)} className="form-control" type="text" placeholder="Buscar llave" />
                {
                partLoading ? 'Bucando...' :
                    selectedParts.length > 0 ?
                    <Button onClick={addBom} className="mt-2"><i className="fa fa-plus"></i> Agregar Materiales</Button> : valKeyValue !== '' ? <p className="mt-1" style={{color:'red'}}>No encontrado</p> : null
                }
            </Col>
          <div className="row" style={{display:'flex', justifyContent:'center', marginLeft:0, marginTop:20}}>
            {
              pediment.map((pediment,i)=>{
                return(
                  <Row className="mb-2 p-1" style={{background:'#efefef', borderRadius:5}}>
                    <Col md="3" xs="12">
                      <input className="form-control" placeholder="No. Parte" type="text" onChange={(e)=>changePedimentValue(pediment.pid,'number',e.target.value)} value={pediment.number} />
                    </Col>
                    <Col md="3" xs="12">
                      <input className="form-control"  type="text" placeholder="Descripción" onChange={(e)=>changePedimentValue(pediment.pid,'description',e.target.value)} value={pediment.description}  />
                    </Col>
                    <Col md="3" xs="12">
                      <input className="form-control" type="number" placeholder="Cantidad" onChange={(e)=>changePedimentValue(pediment.pid,'qty',e.target.value)} value={pediment.qty}  />
                    </Col>
                    <Col md="3" xs="12">
                      <input className="form-control" type="text" placeholder="Pedimento" onChange={(e)=>changePedimentValue(pediment.pid,'pediment',e.target.value)} value={pediment.pediment}  />
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        
        </Row>
      </div>
    );
  };