import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Nav, Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert, } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { API_QUOTE_PART, API_CUSTOMER, API_USER, API_ITEM } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";

const PartsQuotes = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinEvent(false); clearModal();};

  const clearModal = () => {
    setCustomerSelected([]);
    setCurrentQuote({});
    setUserSelected([]);
    setParts([]);
    setselectedPart(null);
    setEditDocument(true);
    setPrimarycolorLineTab('1');
    settotal(0);
    setTemporalPartCounter(0);

    setpriceType("LIST");
    setcreditType("100_FULL");
  }

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=COTIZADOR,ADMIN,GERENTE`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [customerSelected,setCustomerSelected] = useState([]);
  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Clientes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataQuoteList, setDataQuoteList] = useState([]);
  const [isGetEventList, setIsGetEventList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getQuoteList = async () => {
    setIsGetEventList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "GERENTE" || user.role === "SUPERVISOR"){
      setEditPermissions(true);
    }

    if(user.role === "COTIZADOR"){
      queryRule = 'owner='+user.uuid;
      setEditPermissions(false);
    }
    
    await axios.get(`${API_QUOTE_PART}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataQuoteList(response.data.docs);
      }else{
        setDataQuoteList([]);
      }
      setIsGetEventList(false);
    }).catch(e => {
      setIsGetEventList(false);
      toast.error("No se pudo obtener el listado de Cotizaciones: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "Id Cotización",
      selector: (row) => <b>{row.quotePartId}</b>,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.customer !== null && row.customer !== undefined && row.customer.length > 0 ? <b>{row.customer[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Responsable",
      selector: (row) => row.owner !== null && row.owner !== undefined && row.owner.length > 0 ? <b>{row.owner[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      center: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.createdAt ? new Date(row.createdAt).getDate() + '-' + (new Date(row.createdAt).getMonth() + 1) + '-' + new Date(row.createdAt).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Total",
      selector: (row) => row.total !== null && row.total !== undefined ? '$'+row.total.toFixed(2) : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}>{row.edit ? <i className="fa fa-pencil"></i> : <i className="fa fa-pencil"></i>}</Button>
                          {editPermissions?
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> : null}
                        </div>,
      width: '160px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingEvent, setSaveQuote] = useState(false);
  const saveQuote = async (data) => {
    setSaveQuote(true);
    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      let partsErrors = false;
      if(parts.length>0){
        parts.map((part)=>{
          if(part.part===""){
            alert("Asigna el No de Partida a "+part.key);
            partsErrors = true;
          }
          if(part.estimatedTime===""){
            alert("Asigna tiempo de entrega para "+part.key);
            partsErrors = true;
          }
          if(part.qty==="" || part.qty < 1){
            alert("Asigna una candidad para "+part.key);
            partsErrors = true;
          }
        })
      }

      if(partsErrors){
        setSaveQuote(false);
        return;
      }

      data.uuid = uuidv4();
      data.customer = customerSelected;
      data.owner = userSelected;
      data.total = total;
      data.status = 'PENDING';
      data.parts = parts;
      
      await axios.post(`${API_QUOTE_PART}`,data).then(response => {
        if(response.data){
          getQuoteList();
          setEditinEvent(true);
          toast.success("¡Cotización guardada con éxito! Continua Editando", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveQuote(false);
        setCurrentQuote(response.data);
      }).catch(e => {
        setSaveQuote(false);
        toast.error("No se pudo crear la cotización: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentQuote, setCurrentQuote] = useState({});
  const [editDocument, setEditDocument] = useState(true);
  const handleEditAction = (event) => {
    toggle();
    setEditinEvent(true);
    setCurrentQuote(event);
    
    if(event.customer!==undefined && event.customer !== null && event.customer.length>0){
      dataCustomers.map((customer)=>{
        if(customer.uuid===event.customer[0].uuid){
          setCustomerSelected([customer]);
        }
      });
    }

    if(event.owner!==undefined && event.owner !== null){
      dataUsers.map((user)=>{
        if(user.uuid===event.owner[0].uuid){
          setUserSelected([user]);
        }
      });
    }

    populate(event);
    
    setTimeout(()=>{
      setValue('quotePartId', event.quotePartId);
      setValue('priceType', event.priceType);
      setValue('contact', event.contact);
      setValue('creditType', event.creditType);
    },500)
  }

  const populate = (event) => {
    let memoryCounter = 0;
    event.parts.map((part)=>{
      if(part.item>=memoryCounter){
        memoryCounter = part.item+1;
      }
    });

    setTemporalPartCounter(memoryCounter);
    setParts(event.parts);
    settotal(event.total);

    setpriceType(event.priceType);
    setcreditType(event.creditType);
  }

  const [isEditingEvent, setEditinEvent] = useState(false);
  const [patchingEvent, setPatchingEvent] = useState(false);
  const patchEvent = async (data) => {
    setPatchingEvent(true);

    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveQuote(false);
        return;
      }

      data.customer = customerSelected;
      data.owner = userSelected;
      data.parts = parts;
      data.total = total;
      data.status = 'PENDING';

      await axios.patch(`${API_QUOTE_PART}/${currentQuote.uuid}`,data).then(response => {
        if(response.data){
          getQuoteList();
          setPatchingEvent(false);
          toast.success("¡Cotización actualizada con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingEvent(false);
      }).catch(e => {
        setPatchingEvent(false);
        toast.error("No se pudo actualizar la cotización: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la cotización para: ${data.customer[0].name} la información se borrará, la acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {

        await axios.delete(`${API_QUOTE_PART}/${data.uuid}`).then(response => {
          getQuoteList();
          SweetAlert.fire("¡Listo!", "cotización eliminada", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la cotización: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataQuoteList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [priceType, setpriceType] = useState('LIST');
  const [creditType, setcreditType] = useState('100_FULL');
  
  const [total, settotal] = useState(0);
  const [parts, setParts] = useState([]);
  const [selectedPart, setselectedPart] = useState(null);
  
  const [partLoading, setPartLoading] = useState(false);
  const [partKeyValue,setpartKeyValue] = useState('');
  const [temporalPartCounter, setTemporalPartCounter] = useState(0);

  const getPartKey = async (value) => {
    setpartKeyValue(value);

    if(value===''){
      setselectedPart(null);
      return;
    }
    setPartLoading(true);

    await axios.get(`${API_ITEM}?country=MX&type=PART&key=${encodeURIComponent(value)}`).then(response => {
      if(response.data.docs.length>0){
        const part = {
          uuid: response.data.docs[0].uuid,
          key: response.data.docs[0].key,
          description: response.data.docs[0].description,
          part: '',
          price: response.data.docs[0].price * .85 * .9 * .9,
          qty: 0,
          estimatedTime: '',
          item: temporalPartCounter,
        }
        setselectedPart(part);
      }else{
        setselectedPart(null);
      }
      setPartLoading(false);
    }).catch(e => {
      setPartLoading(false);
    });
  }

  const deleteItem = (item) => {
    let temporalParts = [];
    parts.map((value)=>{
      if(value.item!==item){
        temporalParts.push(value);
      }
    });

    setParts(temporalParts);
    calculateTotalPrice();
  }

  const editQtyAndPrice = (value,uuid,item) => {
    let temporalParts = [];
    parts.map((part)=>{
      if(part.uuid===uuid&&part.item===item){
        part.qty = value;
      }
      temporalParts.push(part);
    });

    setParts(temporalParts);
    calculateTotalPrice();
  }

  const calculateTotalPrice = () => {  
    let totalFinal = 0;  
    if(parts.length>0){
      parts.map((part)=>{
        totalFinal += part.qty * part.price;
      })
    }
    settotal(totalFinal);
  }

  const editPartida = (value,uuid,item) => {
    let temporalParts = [];
    parts.map((part)=>{
      if(part.uuid===uuid&&part.item===item){
        part.part = value;
      }
      temporalParts.push(part);
    });

    setParts(temporalParts);
  }

  const editEstimatedTime = (value,uuid,item) => {
    let temporalParts = [];
    parts.map((part)=>{
      if(part.uuid===uuid&&part.item===item){
        part.estimatedTime = value;
      }
      temporalParts.push(part);
    });

    setParts(temporalParts);
  }

  const calculateDisccount = (value) => {
    setpriceType(value);
    
    switch(value){
      case 'LIST' :
        // settotal(((Number(valvePrice > 0 ? valvePrice : 0) + Number(actPrice > 0 ? actPrice : 0) + Number(posPrice > 0 ? posPrice : 0))*qty).toFixed(2));
      break;
    }
  }

  const addPartNumber = () => {
    let temporalParts = [];
    parts.map((value)=>{
      temporalParts.push(value);
    });
    temporalParts.push(selectedPart);

    setTemporalPartCounter(temporalPartCounter+1);
    setParts(temporalParts);
    setselectedPart(null);
    setpartKeyValue('');
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
      && user.role !== "COTIZADOR"
      && user.role !== "SUPERVISOR"
    ){
      navigate('/inicio');
    }
    getCustomersList();
    getQuoteList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Ventas" title="Cotizar Partes"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Cotización</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>No se encontraron cotizaciones</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
      <ModalHeader toggle={toggle}>{isEditingEvent ? `Editar Cotización` : `Crear Cotización` }
        {isEditingEvent ?
        <>
          <div style={{display:'flex',justifyContent:'left'}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/quote-part?uuid=${currentQuote.uuid}`} target="_blank">Ver Documento</a></div>
        </>
        : null }
        <button className="btn-close invisible" type="button">
          <span aria-hidden="true" className="visible" onClick={toggle}></span>
        </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <h5>Resumen</h5>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Cliente
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {
                      customerSelected.length > 0 ?
                        customerSelected[0].name
                      : <Alert color="warning" className="p-1 m-0">Sin elegir</Alert>
                    }
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Tipo de Crédito
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {creditType === '100_FULL' ? '100% anticipo' : null}
                    {creditType === '30_DAYS' ? '30 días de crédito' : null}
                    {creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                    {creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                  </Label>
                </Col>
              </Row>
              <hr/>
              <Row className="flex d-flex" md="12">
                <Col md="9" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    Total USD
                  </Label>
                </Col>
                <Col md="3" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    ${total.toLocaleString()}
                  </Label>
                </Col>
              </Row>
            </div>
            {
              editDocument ?
                <>
                
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingEvent ? patchEvent : saveQuote)} style={{padding:20}}>

                  <Nav className="nav-primary" tabs>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "1" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("1")}
                      >
                        <i className="icofont icofont-list"></i>Detalles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "2" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("2")}
                      >
                        <i className="icofont icofont-listing-box"></i>Partes ({parts.length})
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={PrimarycolorLineTab}>
                    <TabPane className="fade show" tabId="1">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validquoteId">
                              No. Cotización
                            </Label>
                            <input className="form-control" id="validquoteId" type="text" placeholder="No. Cotización" name="quotePartId" {...register("quotePartId", { required: true })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validPriceType">
                              Tipo Precio
                            </Label>
                            <select className="form-control" defaultValue={priceType} name="priceType" {...register("priceType", { required: true })} onChange={(e)=>calculateDisccount(e.target.value)} >
                              <option value="LIST" selected>LISTA F</option>
                              {/* <option value="SALE">VENTA</option>
                              <option value="REP">REP</option> */}
                            </select>
                            <span>{errors.priceType && "Tipo de Precio requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Cliente
                            </Label>
                            <Typeahead
                              id="customers"
                              clearButton
                              defaultSelected={customerSelected}
                              labelKey="name"
                              options={dataCustomers}
                              placeholder="Buscar Cliente..."
                              onChange={(e)=>{setCustomerSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validContact">
                              Atención
                            </Label>
                            <input className="form-control" id="validContact" type="text" placeholder="Persona de contacto" name="contact" {...register("contact", { required: true })} />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validCredit">
                              Crédito
                            </Label>
                            <select className="form-control" name="creditType" {...register("creditType", { required: true })} onChange={(e)=>setcreditType(e.target.value)} >
                              <option value="30_DAYS">30 días de crédito</option>
                              <option value="50_50">50% anticipo y 50% contra aviso de embarque</option>
                              <option value="100_ADVICE_SHIPPING">100% contra aviso de embarque</option>
                              <option value="100_FULL" selected>100% anticipo</option>
                            </select>
                            <span>{errors.creditType && "Tipo de Crédito requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Realiza
                            </Label>
                            <Typeahead
                              id="users"
                              clearButton
                              defaultSelected={userSelected}
                              labelKey="name"
                              options={dataUsers}
                              placeholder="Buscar Usuarios..."
                              onChange={(e)=>{setUserSelected(e)}}
                            />
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <input value={partKeyValue} onChange={(e)=>getPartKey(e.target.value)} className="form-control" type="text" placeholder="Buscar número de parte" />
                            {
                              partLoading ? 'Bucando...' :
                                selectedPart!== null ?
                                <Button onClick={addPartNumber} className="mt-2"><i className="fa fa-plus"></i> Agregar</Button> : partKeyValue !== '' ? <p className="mt-1" style={{color:'red'}}>No encontrado</p> : null
                            }
                          </Col>
                        </Row>
                        {parts.length > 0 ?
                          parts.map(part=>{
                            return(
                              <Row key={part.uuid+''+part.item} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                                <div style={{color:'white', background:'red', position:'absolute', right:60, marginTop:0-10, padding:5, borderRadius:5, width:20, textAlign:'center', cursor:'pointer'}} onClick={()=>deleteItem(part.item)}>X</div>
                                <Col md="12">
                                  <Label className="form-label font-weight-bold">
                                    No. Parte: {part.key}
                                  </Label><br/>
                                  <Label className="form-label font-weight-bold">
                                    Descripción: <b style={{color:'green'}}>{part.description}</b>
                                  </Label><br/>
                                  <Label className="form-label font-weight-bold">
                                    Precio ${part.price.toFixed(2)}
                                  </Label>
                                  &nbsp;&nbsp;- &nbsp;&nbsp;
                                  <Label className="form-label font-weight-bold">
                                    Total ${(part.price * part.qty).toFixed(2)}
                                  </Label>
                                </Col>
                                <Col md="4" xs="12">
                                  <Label className="form-label font-weight-bold" for="validPart">
                                    Partida
                                  </Label>
                                  <input value={part.part} className="form-control" type="text" placeholder="No. partida"  onChange={(e)=>{editPartida(e.target.value, part.uuid,part.item);}} name="part" />
                                </Col>
                                <Col md="4" xs="12">
                                  <Label className="form-label font-weight-bold" for="validQty">
                                    Cantidad
                                  </Label>
                                  <input className="form-control" type="number" placeholder="Cantidad" value={part.qty} onChange={(e)=>{editQtyAndPrice(e.target.value, part.uuid,part.item);}} />
                                </Col>
                                <Col md="4" xs="12">
                                  <Label className="form-label font-weight-bold" for="validEstimated">
                                    Tiempo de entrega
                                  </Label>
                                  <input className="form-control" type="text" placeholder="Tiempo estimado" value={part.estimatedTime}  onChange={(e)=>{editEstimatedTime(e.target.value, part.uuid,part.item);}} />
                                </Col>
                              </Row>
                            )
                          })
                        : null }
                    </TabPane>
                  </TabContent>

                  <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                    <span style={{color:'red'}}>{errors.quotePartId && "- No. Cotización Requerido"}</span>
                    <span style={{color:'red'}}>{errors.contact && "- Persona de Atención Requerida"}</span>
                    {
                      isEditingEvent ? 
                        <Button disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                      : 
                        <Button disabled={savingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                    }
                  </Row>

                </Form>
                </>
              :
              null
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default PartsQuotes;
